import axios from "axios";
import { BASE_URL } from "../constants";
import Cookies from "js-cookie";

const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    // Get token from cookies
    const token = Cookies.get("access_token");

    // Add Authorization header if token exists
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export const loginByUsername = async (data) => {
  try {
    const response = await api.post("/auth/login", data);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getUserById = async (id) => {
  try {
    const response = await api.get(`/users/${id}`);
    return response.data; // Return only the data part of the response
  } catch (error) {
    console.error("Error fetching user by ID:", error);
    return error.response ? error.response.data : error.message; // Return a consistent error format
  }
};

export const getUsersList = async (params) => {
  try {
    const response = await api.get("/users", { params });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getFlightList = async (params) => {
  try {
    const response = await api.get("/flights", { params });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const deleteFlightById = async (flightId) => {
  try {
    const response = await api.delete(`/flights/${flightId}`);
    return response;
  } catch (error) {
    console.error(`Error deleting flight with ID ${flightId}:`, error);
    return error.response; // Return the error response for better error handling
  }
};

export const deleteFlights = async () => {
  try {
    const response = await api.delete("/flights");
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const createFlight = async (flightData) => {
  try {
    const response = await api.post("/flights", flightData); // Adjust URL as needed
    return response.data;
  } catch (error) {
    console.error("Error creating flight:", error);
    throw error;
  }
};

export const createUser = async (data) => {
  try {
    const response = await api.post("/users", data);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await api.patch(`/users/${id}`, data);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await api.delete(`/users/${id}`);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getMultipartUploadId = async (fileName) => {
  try {
    const response = await api.post(`/file`, { name: fileName });
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const completeMultipartUpload = async (fileName, uploadId, parts) => {
  try {
    const response = await api.post(`/file/${uploadId}/complete`, {
      name: fileName,
      parts,
    });
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getPresignedUrl = async (uploadId, fileName, partNumber) => {
  try {
    const response = await api.post(`/file/${uploadId}`, {
      name: fileName,
      partNumber,
    });
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

export const getPresignedReadUrl = async (flightId) => {
  try {
    const response = await api.get(`/file?flightId=${flightId}`);
    return response;
  } catch (error) {
    console.error(error);
    return error.response;
  }
};

// New function to get locations
export const getLocations = async (limit) => {
  try {
    const response = await api.get(`/locations?limit=${limit}`);

    return response.data; // Adjusted to return only the data
  } catch (error) {
    console.error("Error fetching locations:", error);
    return error.response;
  }
};

export default api;
