import React from "react";
import PropTypes from "prop-types";

const DeleteUserModal = ({
  show,
  onClose,
  onConfirm,
  itemName,
  isFlightDelete,
}) => {
  if (!show) {
    return null;
  }
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-10">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800 flex items-center">
            Delete Confirmation
          </h2>
          <button
            className="text-gray-600 hover:text-red-600"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <p className="text-gray-700 mb-4 text-left">
          Are you sure you want to delete{" "}
          <strong>{itemName} ?</strong>  This action
          cannot be undone.
        </p>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg mr-2 transition duration-200 hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="bg-red-600 text-white px-4 py-2 rounded-lg transition duration-200 hover:bg-red-500"
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

DeleteUserModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
};

export default DeleteUserModal;
