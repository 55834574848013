import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { deleteFlights, loginByUsername } from "../api";
import { HttpStatusCode } from "axios";
import Footer from "./Footer";
import {Mail, Lock, EyeIcon, EyeClosed} from "lucide-react";

import schenllDrone from "../assets/IndianMan.png";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setAuthContext } = useAuth();

  const handleDeleteFlights = async () => {
    try {
      const response = await deleteFlights();
      if (response.status === 200) {
       
      }
    } catch (error) {
      console.log("error while deleting files");
    }
    //  setIsMenuOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await loginByUsername({ email, password });
      if (response.status === HttpStatusCode.Ok) {
        handleDeleteFlights();
      }
      if (!response.status === HttpStatusCode.Ok) {
        throw new Error("Login failed");
      }

      setAuthContext({
        user: response.data.user,
        tokens: response.data.tokens,
      });

      const userRole = response.data.user.role;
      if (userRole === "admin") {
        navigate("/users");
      } else if (userRole === "pilot" || userRole === "viewer") {
        navigate("/surveillance");
      } else {
        setError("Unknown user role");
      }
    } catch (err) {
      setError("Invalid email or password. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      <main className="flex-grow flex flex-col items-center px-4 py-8 md:py-12">
        {/* Header Section - Now part of the main content area */}
        <div
          style={{ paddingTop: "50px" }}
          className="w-full max-w-6xl mb-8 md:mb-12"
        >
          <div className="text-center space-y-3">
            <h1 className="text-5xl md:text-6xl font-bold text-gray-900 tracking-tight font-serif">
              Department of Fisheries
            </h1>
            <h2 className="text-2xl md:text-3xl font-medium text-gray-600">
              Government of Maharashtra, India
            </h2>
          </div>
        </div>

        {/* Login and Image Container */}
        <div
          style={{ paddingTop: "60px" }}
          className="w-full max-w-6xl grid md:grid-cols-2 gap-8 lg:gap-16"
        >
          {/* Login Form */}
          <div className="bg-white rounded-2xl shadow-xl p-6 md:p-8 lg:p-10">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-2">
                <h3 className="text-2xl md:text-3xl font-bold text-gray-900">
                  Sign In
                </h3>
                <p className="text-gray-600">
                  Please sign in to access your account
                </p>
              </div>

              {error && (
                <div className="bg-red-50 text-red-600 px-4 py-3 rounded-lg text-sm">
                  {error}
                </div>
              )}

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                    Email Address
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      name="email"
                      type="email"
                      required
                      className="block w-full pl-10 pr-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900 placeholder-gray-500"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1 text-left">
                    Password
                  </label>
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Lock className="h-5 w-5 text-gray-400"/>
                    </div>
                    <input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        required
                        className="block w-full pl-10 pr-10 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-900 placeholder-gray-500"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                        type="button"
                        onClick={() => setShowPassword((prev) => !prev)}
                        className="absolute inset-y-0 right-3 flex items-center"
                    >
                      {showPassword ? (
                        <EyeIcon className="h-5 w-5 text-gray-500"/>
                        ) : (
                        <EyeClosed className="h-5 w-5 text-gray-500"/>
                      )}
                    </button>
                  </div>
                  </div>
                </div>

                <button
                    type="submit"
                    disabled={loading}
                    className="w-full bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 text-white font-medium py-3 px-4 rounded-lg transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? (
                      <span className="flex items-center justify-center">
                    <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Signing in...
                  </span>
                ) : (
                  "Sign in"
                )}
              </button>
            </form>
          </div>

          {/* Image Section */}
          <div
            style={{ marginTop: "1.7rem" }}
            className="lg:h-[355px] md:h-[400px] max-md:mt-8 mx-auto px-6 lg:px-20 flex items-center justify-center"
          >
            <img
              src={schenllDrone}
              className="w-full h-[410px] max-md:w-4/5 max-md:h-auto mx-auto block object-cover rounded-lg shadow-md"
              alt="Login Illustration"
            />
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer className="mt-auto" />
    </div>
  );
};

export default LoginPage;
