import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

// Create the AuthContext
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

// AuthProvider component that wraps your app
export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true); // Add loading state
  const [auth, setAuth] = useState(null);

  // Function to log in and set auth state
  const setAuthContext = ({ user, tokens }) => {
    // Save only the access token and expires_in in cookies
    Cookies.set("access_token", tokens.access.token, {
      expires: new Date(tokens.access.expires),
    });

    // Save the user object in local storage
    localStorage.setItem("user", JSON.stringify(user));

    // Store the rest of the auth details in memory
    setAuth({
      user,
      tokens: {
        access: tokens.access.token,
      },
    });
  };

  // Function to log out and clear cookies
  const logout = () => {
    Cookies.remove("access_token");
    localStorage.removeItem("user");
    setAuth(null);
  };

  // Function to check if the token has expired or is missing
  const isTokenExpired = () => {
    const accessToken = Cookies.get("access_token");

    // If no token, return true (expired)
    if (!accessToken) {
      return true;
    }

    // Decode the token payload and extract the expiration time (assuming it's a JWT)
    const payload = accessToken.split(".")[1];
    const decodedPayload = JSON.parse(atob(payload));

    const now = Date.now() / 1000; // Current time in seconds
    return decodedPayload.exp < now; // Check if the token expiration time has passed
  };

  // Load the auth state from cookies when the app starts
  useEffect(() => {
    const accessToken = Cookies.get("access_token");
    if (accessToken) {
      const user = JSON.parse(localStorage.getItem("user"));

      setAuth({
        user,
        tokens: { access: accessToken },
      });
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{ auth, setAuthContext, logout, isTokenExpired, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};
