import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  if (isLoginPage) {
    return (
      <div
        className="min-h-[94.8vh] flex flex-col"
      >
        <main className="flex-1">{children}</main>
      </div>
    );
  }

  return (
    <div
      style={{ paddingTop: "1rem" }}
      className="h-screen w-full flex overflow-scroll"
    >
      <SideNav />
      <div className="flex-1 flex flex-col">
        <div className="flex-1 bg-gray-100 p-6 mt-[64px] overflow-y-auto">
          {children}
        </div>
        <Footer className="w-full" />
      </div>
    </div>
  );
};

export default Layout;
