import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SuccessModal = ({ onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Example: Get specific query parameters
  const myParam = queryParams.get("location"); // Replace 'myParam' with your query param key
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-80 text-center">
        <h2 className="text-xl font-semibold text-green-600 mb-4">
          Surveillance Video Upload Success!
        </h2>
        <p className="text-gray-700 mb-6">
          Your video has been uploaded successfully.
        </p>
        <button
          onClick={() => {
            navigate(`/surveillance${myParam ? `?location=${myParam}` : ""}`);
          }}
          className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md transition duration-150 ease-in-out"
        >
          Go to Surveillance
        </button>
        <button
          onClick={onClose}
          className="mt-4 w-full text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out"
        >
          Upload more
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
