import React from "react";

const Footer = ({ className = "" }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={`bg-gradient-to-r from-white via-gray-100 to-gray-200 ${className}`}
    >
      <div className="h-12 flex items-center justify-center">
        <span className="text-sm text-gray-500">
          Copyright © 2010-{currentYear} Schnell Drone Technologies Ltd. All
          rights reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
