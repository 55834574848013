import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import LoginPage from "./components/LoginPage";
import Layout from "./components/Layout";
import Cookies from "js-cookie";
import { FlightAdd, Flights, NotFound, Users } from "./pages";
import Loading from "./components/Loading";

function App() {
  const { auth, isTokenExpired, loading } = useAuth();

  if (loading) {
    return <Loading />;
  }

  const accessToken = Cookies.get("access_token");
  const isAuthenticated = accessToken && !isTokenExpired();

  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            {!isAuthenticated ? (
              // Public Routes
              <>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            ) : (
              // Protected Routes based on user role
              <>
                <Route
                  path="/"
                  element={<Navigate to={getDefaultRoute(auth?.user?.role)} />}
                />

                {/* Admin Routes */}
                {auth?.user?.role === "admin" && (
                  <>
                    <Route path="/users" element={<Users />} />
                    <Route path="/surveillance" element={<Flights />} />
                    <Route path="/surveillance/add" element={<FlightAdd />} />
                  </>
                )}

                {/* Pilot Routes */}
                {auth?.user?.role === "pilot" && (
                  <>
                    <Route path="/surveillance/add" element={<FlightAdd />} />
                    <Route path="/surveillance" element={<Flights />} />
                  </>
                )}

                {/* Viewer Routes */}
                {auth?.user?.role === "viewer" && (
                  <Route path="/surveillance" element={<Flights />} />
                )}

                {/* Catch all route */}
                <Route path="*" element={<NotFound />} />
              </>
            )}
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

// Helper function to get default route based on role
const getDefaultRoute = (role) => {
  switch (role) {
    case "admin":
      return "/users";
    case "pilot":
    case "viewer":
      return "/surveillance";
    default:
      return "/login";
  }
};

export default function Root() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}
