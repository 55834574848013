import React from "react";

const Loading = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-white via-gray-200 to-gray-500">
      <div className="text-center">
        <div className="mb-6">
          {/* Replace the src URL with your drone GIF */}
          <img
            src="https://i.pinimg.com/originals/61/20/56/612056122fba1688f6dc7323bfd21bd6.gif"
            alt="Drone Loading"
            className="w-56 h-56 mx-auto object-cover rounded-full shadow-lg"
          />
        </div>
        <h1 className="text-black text-4xl font-semibold mb-4">
          Preparing Your Experience...
        </h1>
        <p className="text-gray-400 text-lg mb-8">
          Hang tight while we get everything ready for you.
        </p>
      </div>
    </div>
  );
};

export default Loading;
