import React, { useState, useRef, useEffect } from "react";
import {
  Play,
  Pause,
  Volume2,
  VolumeX,
  Maximize,
  Minimize,
  X,
  Clock,
  Download,
  MapPin,
  MessageCircle,
  FileVideo,
} from "lucide-react";
import { getPresignedReadUrl, getPresignedUrl } from "../api";

import { formatTimestamp } from "../utils";

const VideoModal = ({ isOpen, onClose, videoInfo }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState();
  const videoRef = useRef(null);

  const modalRef = useRef(null);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      const fileUrlVideo = await getPresignedReadUrl(videoInfo.id);
      setFileUrl(fileUrlVideo?.data?.downloadUrl);
    };
    if (videoInfo) {
      fetchVideoUrl();
    }
  }, [videoInfo]);

  useEffect(() => {
    // Ensure disablePictureInPicture is set when the component loads
    if (videoRef.current && isOpen) {
      videoRef.current.disablePictureInPicture = true;
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300">
      <div
        ref={modalRef}
        className="bg-white rounded-lg overflow-hidden shadow-2xl max-w-4xl w-full"
      >
        <div className="relative">
          <button
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800 transition-colors duration-200 z-10"
            onClick={onClose}
            aria-label="Close modal"
          >
            <X size={24} />
          </button>
          {/* Video Element */}
          <div className="relative bg-gray-100">
            <video
              disablePictureInPicture={true}
              controls
              ref={videoRef}
              className="w-full h-auto max-w-full max-h-[50vh]"
              src={fileUrl}
            />
          </div>
          {/* Video Information */}
          <div className="p-6 bg-gradient-to-br from-gray-50 to-gray-100">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center space-x-4">
                <div className="flex items-center text-gray-800">
                  <MapPin size={18} className="mr-2 text-indigo-600" />
                  <h2 className="text-xl font-semibold">
                    {videoInfo.location?.name}
                  </h2>
                </div>
                <div className="flex items-center text-gray-600">
                  <Clock size={16} className="mr-2 text-emerald-500" />
                  <span className="text-sm">
                    {formatTimestamp(videoInfo.startTime)} -{" "}
                    {formatTimestamp(videoInfo.endTime)}
                  </span>
                </div>
              </div>
            </div>
            <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
              <div className="flex items-start">
                <MessageCircle
                  size={18}
                  className="mr-3 mt-1 text-amber-500 flex-shrink-0"
                />
                <p className="text-gray-700 text-sm leading-relaxed text-start break-words text-wrap w-[calc(100%-24px)]">
                  {videoInfo.remark}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
