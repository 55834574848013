import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  UserGroupIcon,
  ClipboardDocumentListIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useAuth } from "../context/AuthContext";
import logo from "../assets/logo.png";
import { CctvIcon, FileEditIcon, MenuIcon, Trash } from "lucide-react";
import { deleteFlights, getUserById, getUsersList, updateUser } from "../api";
import { Toaster, toast } from "sonner";
import AddUserModal from "./AddUserModal";
import { HttpStatusCode } from "axios";

const navItems = [
  {
    name: "Costal Drone surveillance",
    path: "/surveillance",
    icon: <CctvIcon className="h-5 w-5 mr-2" />,
  },
];

const TopNav = () => {
  const location = useLocation();
  const { auth, logout, setAuthContext } = useAuth();
  const [items, setItems] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditSelf,setIsEditSelf] = useState(false)

  // Ref for the dropdown menu
  const menuRef = useRef(null);
  // Fetch user data by ID when "Edit Profile" is clicked
  const fetchUserData = async (userId) => {
    try {
      const response = await getUserById(userId); // API call to fetch user
      if (response) {
        setCurrentUser(response);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      toast.error("Failed to fetch user data.");
    }
  };

  // Handle fetching and updating user data
  const handleEditProfile = async () => {
    if (auth?.user?.id) {
      setIsLoading(true);
      setIsEditSelf(true)
      await fetchUserData(auth.user.id); // Fetch user by ID on profile edit
      
      setShowModal(true);
      setIsLoading(false);
    }
  };

  // Function to handle updating user information
  const handleEditUser = async (updatedUserData) => {
    delete updatedUserData.id 
    delete updatedUserData.phone
    try {
      setIsLoading(true);
      const response = await updateUser(auth.user.id, updatedUserData); // API call to update user
      if (response.status === HttpStatusCode.Ok) {
        setCurrentUser(response.data); // Update the currentUser state with updated data

        // Update auth context as well
        // setAuthContext((prevAuth) => ({
        //   ...prevAuth,
        //   user: response.data,
        // }));

        toast.success("Profile Updated", {
          description: "Your profile has been successfully updated.",
          duration: 3000,
        });

        setShowModal(false);
      } else {
        throw new Error("Profile update failed");
      }
    } catch (error) {
      console.error("Update Error:", error);
      toast.error("Update Error", {
        description: error.message || "An unexpected error occurred.",
        duration: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Dynamically set nav items based on user role
  useEffect(() => {
    if (auth?.user?.role === "admin") {
      setItems([
        {
          name: "Users",
          path: "/users",
          icon: <UserGroupIcon className="h-5 w-5 mr-2" />,
        },
        ...navItems,
      ]);
    } else {
      setItems(navItems);
    }
  }, [auth]);

  // Handle clicking outside to close the menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleMenuClick = (path) => {
    setIsMenuOpen(false);
    navigate(path);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
    setIsMenuOpen(false);
  };
const handleAddUser = ()=>{

}
  return (
    <>
      {/* Toaster positioned within the component */}
      <Toaster
        richColors
        position="top-center"
        expand={false}
        className="z-[100]"
      />

      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(255,255,255,1) 10%, rgba(210,80,80,1) 75%, rgba(255,0,0,1) 100%)",
        }}
        className="text-black bg-gray-100 shadow-lg fixed top-0 left-0 w-full z-50"
      >
        <div className="flex justify-between items-center px-6 py-4">
          {/* Left-aligned items */}
          <div className="flex flex-col items-start space-y-1">
            <h1 className="text-xl font-semibold text-red">
              Department of Fisheries
            </h1>
            <p className="text-sm text-red">Government of Maharashtra, India</p>
          </div>

          {/* Right-aligned items */}
          <div className="flex items-center gap-6 ml-auto">
            <div className="text-2xl font-bold">
              <img src={logo} alt="logo" className="rounded-md ml-2" />
            </div>

            {/* Menu Icon for admin */}
            {/* {auth?.user?.role === "admin" && ( */}
            <div
              className="flex justify-center items-center p-2 text-black bg-red-500 hover:bg-red-200 hover:text-black font-bold rounded cursor-pointer transition duration-200"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <MenuIcon className="h-5 w-5" />
            </div>
            {/* )} */}

            {/* Dropdown Menu for Admin */}
            {isMenuOpen && (
              <div
                ref={menuRef}
                className="absolute top-16 right-5 bg-white shadow-md rounded-lg z-10"
              >
                <nav className="flex flex-col min-w-60">
                  {auth?.user?.role === "admin" &&
                    items.map((item) => (
                      <div
                        key={item.name}
                        onClick={() => handleMenuClick(item.path)}
                        className="flex items-center p-3 text-black hover:bg-gray-200 cursor-pointer rounded-md transition duration-200"
                      >
                        {item.icon}
                        <span>{item.name}</span>
                      </div>
                    ))}
                  {/* {auth?.user?.role === "admin" && (
                    <div
                      onClick={handleDeleteFlights}
                      className="flex items-center p-3 text-black hover:bg-gray-200 cursor-pointer transition duration-200"
                    >
                      <Trash className="h-5 w-5 mr-2" />
                      <span>Clear old data</span>
                    </div>
                  )} */}
                  {/* Admin Logout Button with consistent menu styling */}
                  <div
                    onClick={handleEditProfile}
                    className="flex items-center p-3 text-black hover:bg-gray-200 cursor-pointer rounded transition duration-200"
                  >
                    <FileEditIcon className="h-5 w-5 mr-2" />
                    <span>Profile</span>
                  </div>
                  <div
                    onClick={handleLogout}
                    className="flex items-center p-3 text-black hover:bg-gray-200 cursor-pointer transition duration-200"
                  >
                    <UsersIcon className="h-5 w-5 mr-2" />
                    <span>Logout</span>
                  </div>
                </nav>
              </div>
            )}

            {/* Non-admin Logout Button */}
            {/* {auth?.user?.role !== "admin" && (
              <div
                onClick={handleLogout}
                className="flex items-center p-2 bg-red-500 hover:bg-red-200 hover:text-black text-white font-bold rounded cursor-pointer transition duration-200"
              >
                <UsersIcon className="h-5 w-5 mr-2" />
                <span>Logout</span>
              </div>
            )} */}
          </div>
        </div>
        <AddUserModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onAddUser={currentUser ? handleEditUser : handleAddUser}
          user={currentUser}
          isEditSelf={isEditSelf}
          setIsEditSelf={setIsEditSelf}
        />
      </div>
    </>
  );
};

export default TopNav;
