import React, { useEffect, useState } from "react";
import { createFlight, getLocations, getPresignedUrl } from "../api/index";
import {
  PlusCircle,
  X,
  Upload,
  Check,
  Send,
  Clock,
  FileVideo,
} from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessModal from "../components/SuccessModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import FlightForm from "../components/FlightForm";

const FlightAdd = () => {
  const [flights, setFlights] = useState([
    {
      location: "",
      locations: [],
      district: "",
      startTime: "",
      endTime: "",
      remark: "",
      file: null,
      uploadProgress: 0,
      uploadComplete: false,
      isUploading: false,
      estimatedTime: "",
    },
  ]);

  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [locationData, setLocationData] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locationsData = await getLocations(100);
        const uniqueDistricts = [
          ...new Map(
            locationsData.results.map((item) => [item.district, item])
          ).values(),
        ];
        setDistricts(uniqueDistricts);
        setLocationData(locationsData.results);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  const [isFormValid, setIsFormValid] = useState(false);
  useEffect(() => {
    const validateForm = () => {
      const allValid = flights.every(
        (flight) =>
          flight.location && flight.startTime && flight.endTime && flight.file
      );
      setIsFormValid(allValid);
    };

    validateForm();
  }, [flights]);

  const addFlight = () => {
    setFlights([
      ...flights,
      {
        location: "",
        locations: [],
        district: "",
        startTime: "",
        endTime: "",
        remark: "",
        file: null,
        uploadProgress: 0,
        uploadComplete: false,
        isUploading: false,
        estimatedTime: "",
      },
    ]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const flightDataArray = flights.map((flight) => ({
      location: flight.location,
      startTime: flight.startTime,
      endTime: flight.endTime,
      remark: flight.remark,
      fileUrl: flight.file ? flight.file.name : null,
    }));

    try {
      for (const flightData of flightDataArray) {
        await createFlight(flightData);
      }
      setShowSuccess(true);
    } catch (error) {
      alert("Failed to submit flights. Please try again.");
    }
  };

  // Rest of the component remains the same as in the previous implementation
  // (rendering logic stays unchanged)

  return (
    <div className=" from-blue-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">
          Add surveillance
        </h1>
        <form onSubmit={handleSubmit} className="space-y-8">
          {flights.map((flight, index) => (
            <FlightForm
              flight={flight}
              index={index}
              districts={districts}
              flights={flights}
              locationData={locationData}
              setFlights={setFlights}
            />
          ))}
          <div className="flex justify-between items-center">
            {/* Add Another Flight Button */}
            <button
              type="button"
              onClick={addFlight}
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <PlusCircle className="w-5 h-5 mr-2" />
              Add Another Flight
            </button>

            {/* Submit Button */}
            <div className="flex space-x-4">
              {/* Cancel Button */}
              <button
                type="button"
                onClick={() => navigate(-1)} // Navigate back to the previous page
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>

              {/* Submit Button */}
              <button
                disabled={!isFormValid || flights.some((f) => f.isUploading)}
                type="submit"
                className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  isFormValid && !flights.some((f) => f.isUploading)
                    ? "bg-blue-600 hover:bg-blue-700 focus:ring-blue-500"
                    : "bg-gray-400 cursor-not-allowed"
                }`}
              >
                <Send className="w-5 h-5 mr-2" />
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
      {showSuccess && (
        <SuccessModal
          onClose={() => {
            setFlights([
              {
                location: "",
                district: "",
                locations: [],
                startTime: new Date(),
                endTime: new Date(),
                remark: "",
                file: null,
                uploadProgress: 0,
                uploadComplete: false,
                isUploading: false,
                estimatedTime: "",
              },
            ]);
            setShowSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export default FlightAdd;
