import * as React from "react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

// Extend dayjs with isSameOrBefore plugin
dayjs.extend(isSameOrBefore);

export default function CustomeTimePicker({
  value,
  onChange,
  minDate,
  maxDate,
  filterTime,
  startTime = null, // Optional prop to handle end time validation
}) {
  const dayjsValue = value ? dayjs(value) : null;

  const handleChangeTime = (newValue) => {
    const standardDate = newValue ? newValue.toDate() : null;
    if (onChange) {
      onChange(standardDate);
    }
  };

  const calculateMinDate = () => {
    const today = dayjs();
    const sixteenDaysAgo = today.subtract(16, "day");

    if (minDate) {
      const providedMinDate = dayjs(minDate);
      return providedMinDate.isAfter(sixteenDaysAgo)
        ? providedMinDate
        : sixteenDaysAgo;
    }

    return sixteenDaysAgo;
  };

  const shouldDisableTime = (value, view) => {
    const selectedTime = dayjs(value);

    if (startTime) {
      const start = dayjs(startTime);

      // Disable all earlier dates
      if (selectedTime.isBefore(start, "day")) {
        return true;
      }

      // Disable earlier hours on the same day
      if (view === "hours" && selectedTime.isSame(start, "day")) {
        return selectedTime.hour() < start.hour();
      }

      // Disable earlier AM/PM if the start time is in PM
      if (
        view === "hours" &&
        selectedTime.isSame(start, "day") &&
        start.hour() >= 12 &&
        selectedTime.hour() < 12
      ) {
        return true; // Disable AM hours
      }

      // Disable earlier minutes within the same hour
      if (view === "minutes" && selectedTime.isSame(start, "hour")) {
        return selectedTime.minute() <= start.minute();
      }

      // Disable earlier seconds within the same minute
      if (view === "seconds" && selectedTime.isSame(start, "minute")) {
        return selectedTime.second() <= start.second();
      }
    }

    return false;
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        format="DD/MM/YYYY hh:mm A"
        value={dayjsValue}
        onChange={handleChangeTime}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        minDate={startTime ? dayjs(startTime) : calculateMinDate()}
        maxDate={maxDate ? dayjs(maxDate) : dayjs()}
        shouldDisableTime={shouldDisableTime}
        sx={{
          backgroundColor: "white",
          color: "#4b5563",
          "&:focus": {
            outline: "none",
            ring: "2px solid rgba(59, 130, 246, 0.5)",
            borderColor: "#3b82f6",
          },
          transition: "all 150ms ease-in-out",
          appearance: "none",
          "& .MuiInputBase-root": {
            paddingTop: "-0.80rem",
            paddingBottom: "-0.80rem",
            height: "3.1rem",
          },
        }}
      />
    </LocalizationProvider>
  );
}
