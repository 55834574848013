import React, { useState, useEffect } from "react";
import {
  Search,
  ChevronLeft,
  ChevronRight,
  Eye,
  Filter,
  TrashIcon,
  Download,
  Trash2Icon,
} from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import VideoModal from "../components/VideoModal";
import {
  deleteFlightById,
  getFlightList,
  getLocations,
  getPresignedReadUrl,
} from "../api";
import { HttpStatusCode } from "axios";
import { formatTimestamp } from "../utils";
import { useAuth } from "../context/AuthContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Toaster, toast } from "sonner";
import DeleteUserModal from "../components/DeleteUserModal";

const Flights = () => {
  const [flightsData, setFlightsData] = useState({
    results: [],
    totalPages: 0,
    totalResults: 0,
  });
  const { auth, isTokenExpired, loading } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoInfo, setVideoInfo] = useState();

  // Updated filter states
  const [districtFilter, setDistrictFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [startTimeFilter, setStartTimeFilter] = useState("");
  const [fileUrl, setFileUrl] = useState();

  const [maxDate, setMaxDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [minDate, setMinDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 20))
      .toISOString()
      .split("T")[0]
  );

  // Updated state to store grouped locations
  const [groupedLocations, setGroupedLocations] = useState({});
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isFlightDelete, setIsFlightDelete] = useState(false);

  const flightsPerPage = 12;

  const formatDateForApi = (date) => {
    if (!date) return null;
    return date.toISOString();
  };

  const fetchFlights = async (page, district, location, time) => {
    try {
      const queryParams = {
        limit: flightsPerPage,
        page,
        sortBy: "createdAt:desc",
      };

      if (location) {
        queryParams.location = location;
      }

      if (time) {
        const formattedDate = formatDateForApi(time);
        if (formattedDate) {
          queryParams.startTime = formattedDate;
        }
      }

      const response = await getFlightList(queryParams);

      if (response?.status === HttpStatusCode.Ok) {
        setFlightsData(response.data);

        // Adjust current page if needed
        if (response.data.totalPages < currentPage) {
          setCurrentPage(response.data.totalPages || 1);
        }
      }
    } catch (error) {
      console.error("Error fetching flights:", error);
    }
  };

  useEffect(() => {
    // Fetch flights when any of the filters change
    if (districtFilter && locationFilter) {
      fetchFlights(
        currentPage,
        districtFilter,
        locationFilter,
        startTimeFilter
      );
    } else {
      // If district is selected, but no location, show no flights available
      setFlightsData({
        results: [],
        totalPages: 0,
        totalResults: 0,
      });
    }
  }, [currentPage, districtFilter, locationFilter, startTimeFilter]);

  // Modified location fetching and grouping
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const locationsData = await getLocations(100);

        // Group locations by district
        const grouped = locationsData.results.reduce((acc, location) => {
          const district = location.district;
          if (!acc[district]) {
            acc[district] = [];
          }
          acc[district].push(location);
          return acc;
        }, {});

        // Sort districts alphabetically
        const sortedGroupedLocations = Object.keys(grouped)
          .sort()
          .reduce((acc, key) => {
            acc[key] = grouped[key];
            return acc;
          }, {});

        setGroupedLocations(sortedGroupedLocations);
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);


  // Update filtered locations when district changes
  useEffect(() => {
    if (districtFilter) {
      const locations = groupedLocations[districtFilter] || [];
      setFilteredLocations(
        locations.sort((a, b) => a.name.localeCompare(b.name))
      );
      // Reset location filter if it's not in the new filtered locations
      if (
        locationFilter &&
        !locations.some((loc) => loc.id === locationFilter)
      ) {
        setLocationFilter("");
      }
    } else {
      // If no district is selected, flatten all locations and sort them
      const allLocations = Object.values(groupedLocations).flat();
      setFilteredLocations(
        allLocations.sort((a, b) => a.name.localeCompare(b.name))
      );
    }
  }, [districtFilter, groupedLocations]);


  const handleDownload = async (flights) => {
    try {
      const response = await getPresignedReadUrl(flights.id);
      const downloadUrl = response?.data?.downloadUrl;
      if (downloadUrl) {
        // Fetch the file as a blob
        const fileResponse = await fetch(downloadUrl);
        if (!fileResponse.ok) {
          throw new Error("Failed to fetch the file for download.");
        }
        const blob = await fileResponse.blob();

        // Create an object URL and trigger the download
        const blobUrl = URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = blobUrl;
        anchor.download = `flight-video-${flights.id}.mp4`; // Optional: Set a filename for the download
        anchor.click();

        // Clean up the object URL to release memory
        URL.revokeObjectURL(blobUrl);
        toast.success("Download completed successfully!");
      } else {
        console.error("Failed to get the download URL.");
      }
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const paginate = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= flightsData.totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getPaginationRange = () => {
    const totalResults = flightsData.totalResults;

    // If no results
    if (totalResults === 0) {
      return "0 results";
    }

    const start = Math.min(
      (currentPage - 1) * flightsPerPage + 1,
      totalResults
    );
    const end = Math.min(currentPage * flightsPerPage, totalResults);

    return `${start} - ${end} of ${totalResults} flights`;
  };

  const handleViewClick = (flight) => {
    setVideoInfo(flight);
    setIsModalOpen(true);
  };

  const handleDateChange = (date) => {
    setStartTimeFilter(date);
  };

  const shouldShowNoFlightsMessage = () => {
    // Show "No flights available" message if no district or location is selected
    return (
      (!districtFilter && !locationFilter) ||
      (districtFilter && !locationFilter) ||
      flightsData.results.length === 0
    );
  };

  // Function to delete a flight
  const handleDeleteFlight = async () => {
    try {
      const response = await deleteFlightById(currentUser);
      if (response?.status === HttpStatusCode.NoContent) {
        toast.success("Flight deleted successfully!");
        setDeleteModalVisible(false);

        // Recalculate the page after deletion
        let newPage = currentPage;
        const remainingFlights = flightsData.totalResults - 1;
        const totalPages = Math.ceil(remainingFlights / flightsPerPage);

        // Adjust page if current page is now out of range
        if (currentPage > totalPages) {
          newPage = totalPages || 1;
        }

        // Re-fetch flights with potentially adjusted page
        await fetchFlights(
          newPage,
          districtFilter,
          locationFilter,
          startTimeFilter
        );

        // Update current page if changed
        setCurrentPage(newPage);
      } else {
        throw new Error("Failed to delete flight");
      }
    } catch (error) {
      console.error("Error deleting the flight:", error);
      toast.error(
        "An error occurred while deleting the flight. Please try again."
      );
    }
  };

  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
    fetchFlights(currentPage, districtFilter, locationFilter, startTimeFilter);
  };

  return (
    <>
      <Toaster
        richColors
        position="top-center"
        expand={false}
        className="z-[100]"
      />
      <div className="bg-white shadow-md rounded-xl p-4 sm:p-6 mb-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col sm:flex-row justify-between items-start space-y-4 sm:space-y-0 gap-4 w-full">
            <div className="w-full sm:w-2/3">
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 w-full">
                {/* District Filter */}
                <div className="flex flex-col">
                  <label
                    htmlFor="district-filter"
                    className="text-start text-xs sm:text-sm text-gray-600 mb-1"
                  >
                    District
                  </label>
                  <select
                    id="district-filter"
                    value={districtFilter}
                    onChange={(e) => {
                      setDistrictFilter(e.target.value);
                      // Reset location when district changes
                      setLocationFilter("");
                    }}
                    className="w-full p-2 sm:p-3 border border-gray-300 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out text-xs sm:text-sm h-12 box-border min-w-0"
                  >
                    <option value="" disabled>
                      Select District
                    </option>
                    {Object.keys(groupedLocations).map((district) => (
                      <option key={district} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Location Filter */}
                <div className="flex flex-col">
                  <label
                    htmlFor="location-filter"
                    className="text-start text-xs sm:text-sm text-gray-600 mb-1"
                  >
                    Location
                  </label>
                  <select
                    id="location-filter"
                    value={locationFilter}
                    onChange={(e) => setLocationFilter(e.target.value)}
                    className="w-full p-2 sm:p-3 border border-gray-300 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out text-xs sm:text-sm h-12 box-border min-w-0"
                    disabled={!districtFilter}
                  >
                    <option value="" disabled>
                      {districtFilter
                        ? "Select Location"
                        : "Select District First"}
                    </option>
                    {filteredLocations.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Start Date Filter */}
                <div className="flex flex-col">
                  <label
                    htmlFor="start-time-filter"
                    className="text-start text-xs sm:text-sm text-gray-600 mb-1"
                  >
                    Start Date
                  </label>
                  <DatePicker
                    selected={startTimeFilter}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    maxDate={maxDate}
                    placeholderText="dd/mm/yy"
                    className="w-full p-2 sm:p-3 border border-gray-300 rounded-md bg-white text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150 ease-in-out text-xs sm:text-sm h-12 box-border min-w-0"
                  />
                </div>
              </div>
            </div>

            {/* Add Flight Button */}
            {["admin", "pilot"].includes(auth?.user?.role) && (
              <div className="w-full sm:w-1/3 mt-4 sm:mt-0 flex justify-end items-center py-4">
                <button
                  onClick={() =>
                    navigate(
                      `/surveillance/add${
                        locationFilter ? `?location=${locationFilter}` : ""
                      }`
                    )
                  }
                  className="w-full sm:w-auto px-6 py-3 bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-xs sm:text-sm transition duration-300 ease-in-out h-12"
                >
                  Add Flight
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Flights Table */}
      <div className="relative flex flex-col justify-between w-full bg-white shadow-md rounded-xl overflow-hidden">
        <div className="flex-grow max-w-full">
          <div className="relative z-0">
            <table className="w-full text-left table-fixed">
              <thead className="bg-gray-50 sticky top-0 z-10">
                <tr>
                  <th className="px-4 py-2 text-left w-[15%]">Location</th>
                  <th className="px-4 py-2 text-left w-[12%]">Start Time</th>
                  <th className="px-4 py-2 text-left w-[12%]">End Time</th>
                  <th className="px-4 py-2 text-left w-[20%]">Remark</th>
                  {auth.user.role === "admin" && (
                    <th className="px-4 py-2 text-left w-[20%]">Pilot</th>
                  )}
                  <th className="px-2 py-2 text-left w-[10%]">Video</th>
                </tr>
              </thead>
            </table>
          </div>

          <div className="max-h-[60vh] overflow-y-auto max-w-full">
            <table className="w-full text-left table-auto">
              <tbody>
                {shouldShowNoFlightsMessage() ? (
                  <tr>
                    <td colSpan="6" className="p-4 text-center text-gray-500">
                      No flights available
                    </td>
                  </tr>
                ) : (
                  flightsData.results.map((flight) => (
                    <tr
                      key={flight.id}
                      className="hover:bg-gray-50 border-b border-slate-200"
                    >
                      <td className="px-4 py-2 text-left w-[15%]">
                        {flight.location?.name}
                      </td>
                      <td className="px-4 py-2 text-left w-[12%]">
                        {formatTimestamp(flight.startTime)}
                      </td>
                      <td className="px-4 py-2 text-left w-[12%]">
                        {formatTimestamp(flight.endTime)}
                      </td>
                      <td className="px-4 py-2 text-left w-[20%]">
                        <div className="relative group max-w-[150px] truncate">
                          <span
                            className="text-sm text-slate-500 cursor-pointer truncate"
                            title={flight.remark}
                          >
                            {flight.remark}
                          </span>
                          <div className="absolute left-0 right-0 bottom-full mb-2 z-20 invisible group-hover:visible opacity-0 ">
                            <div className="bg-gray-800 text-white rounded-lg shadow-lg p-3 max-w-xs">
                              <p className="text-sm">{flight.remark}</p>
                            </div>
                            <div className="w-3 h-3 bg-gray-800 transform rotate-45 absolute -bottom-1.5 left-4"></div>
                          </div>
                        </div>
                      </td>

                      {auth?.user?.role === "admin" && (
                        <td className="px-4 py-2 text-left w-[20%]">
                          {flight.pilot || "-"}
                        </td>
                      )}

                      <td className="px-2 py-2 text-left w-[10%]">
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleViewClick(flight)}
                            className="p-2 rounded-md text-blue-600 hover:bg-blue-50 transition-colors"
                          >
                            <Eye className="w-5 h-5" />
                          </button>
                          <button
                            onClick={() => handleDownload(flight)}
                            className="p-2 rounded-md text-green-600 hover:bg-green-50 transition-colors"
                          >
                            <Download className="w-5 h-5" />
                          </button>
                          {auth?.user?.role === "admin" && (
                            <button
                              onClick={() => {
                                setDeleteModalVisible(true);
                                setIsFlightDelete(true);
                                setCurrentUser(flight.id);
                                setFileUrl(flight.fileUrl);
                              }}
                              className="p-2 rounded-md text-red-600 hover:bg-red-50 transition-colors"
                            >
                              <Trash2Icon className="w-5 h-5" />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div
            style={{ padding: "1rem" }}
            className="py-3 flex justify-between"
          >
            <div className="text-sm">
              <span className="text-gray-600">
                Showing {getPaginationRange()}
              </span>
            </div>

            <div className="flex gap-4">
              <button
                onClick={() => paginate(currentPage - 1)}
                className="p-2 rounded-full bg-gray-200 hover:bg-gray-300"
                disabled={currentPage === 1}
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <button
                onClick={() => paginate(currentPage + 1)}
                className="p-2 rounded-full bg-gray-200 hover:bg-gray-300"
                disabled={currentPage === flightsData.totalPages}
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <DeleteUserModal
        show={isDeleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        onConfirm={handleDeleteFlight}
        itemName={fileUrl}
        isFlightDelete={isFlightDelete}
      />
      {/* Video Modal */}
      <VideoModal
        isOpen={isModalOpen}
        videoInfo={videoInfo}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default Flights;
